import punycode from 'punycode/';
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

interface DomainData {
  data: string[];
}

(() => {
  const recentRegistrationsUrl = 'https://api.my.inc/v1/domains/ticker';
  const bannerSliderWrapper: HTMLElement | null = document.getElementById('banner-slider-wrapper');

  if (bannerSliderWrapper !== null) {
    const splideElement: HTMLElement | null = bannerSliderWrapper.querySelector('.splide');

    if (!splideElement) {
      throw new Error('Splide element not found');
    }

    const splide = new Splide(splideElement, {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      pagination: false,
      arrows: false,
      autoWidth: true,
      autoScroll: {
        speed: 1,
        pauseOnFocus: false,
        pauseOnHover: true,
      },
    }).mount({ AutoScroll });

    if (!splide) {
      throw new Error('Splide instance not created');
    }

    fetch(recentRegistrationsUrl)
      .then(res => res.json())
      .then((data: DomainData) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const domains: string[] = data.data;

        if (!domains || !domains.length) {
          throw new Error('No domains found.');
        }

        const domainGroup = document.createElement('div');
        domainGroup.classList.add('splide__slide');

        for (let i = 0; i < domains.length; i++) {
          const domainDiv = document.createElement('div');
          const domainName = domains[i];
          let domainText = '';
          try {
            domainText = `${punycode.toUnicode(domainName)}`;
          } catch (_err) {
            domainText = domainName;
          }
          domainDiv.textContent = domainText;
          domainDiv.classList.add('domain__text');
          domainGroup.appendChild(domainDiv);
        }

        splide.add(domainGroup);
      })
      .catch(err => console.error('Initializing ticker component failed:', err));
  }
})();
